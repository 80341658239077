export const region = 'voronezh';
export const authCodeLength = 6;
export const paymentPinLength = 6;

export const routes = {
  homepage: 'tasks',
  tasks: 'tasks',
  taskInfo: 'task-info',
  rules: 'rules',
  myActivity: 'my-activity',
  signUp: 'signup',
  forCompanies: 'for-companies',
  dispute: 'dispute',
  userPayment: 'payoff',
  companyInfo: 'company-info',
  finish: 'finish',
  welcome: 'welcome',
  adminPage: 'admin',
  switchUserType: 'select-type',
  places: 'places',
  getUserPayment: 'get-payoff',
  payment: 'payment',
  paymentCode: 'code',
  paymentExecute: 'execute',
  paymentExplain: 'explain',
  paymentExplainPopup: 'explain-popup',
  placeInfo: 'place-info',
  userPlaces: 'user-places',
};

export const solutionStates = {
  moderated: 'moderated',
  approved: 'approved',
  rejected: 'rejected',
  executing: 'executing',
  expired: 'expired',
  rejectedAppeal: 'rejectedAppeal',
  approvedAppeal: 'approvedAppeal',
};

export const paymentStates = {
  created: 'created',
  expired: 'expired',
  approved: 'approved',
  rejected: 'rejected',
};

export const itemTypes = {
  solution: 'solution',
  expense: 'expense',
};

export const paymentTypes = {
  userToPlace: 'userToPlace',
};

export const completedRoutes = {
  signUp: ['', routes.signUp].join('/'),
  signUpModalItem: ['', routes.tasks, routes.signUp].join('/'),
  welcome: ['', routes.welcome].join('/'),
  userPaymentExplain: ['', routes.tasks, routes.userPayment, routes.paymentExplain].join('/'),
  userPaymentExplainPopup: ['', routes.tasks, routes.userPayment, routes.paymentExplainPopup].join('/'),
  userTasks: ['', routes.tasks].join('/'),
  userPlaces: ['', routes.tasks, routes.userPlaces].join('/'),
  userRules: ['', routes.tasks, routes.rules].join('/'),
  userForCompanies: ['', routes.tasks, routes.forCompanies].join('/'),
  taskInfo: (taskId) => ['', routes.tasks, routes.taskInfo, taskId].join('/'),
  taskInfoWithoutId: ['', routes.tasks, routes.taskInfo].join('/'),
  taskFinish: (taskId) => ['', routes.tasks, routes.taskInfo, taskId, routes.finish].join('/'),
  taskOwnerInfo: (placeId) => ['', routes.tasks, routes.companyInfo, placeId].join('/'),
  taskOwnerInfoWithoutId: ['', routes.tasks, routes.companyInfo].join('/'),
  userPayment: ['', routes.tasks, routes.userPayment].join('/'),
  userPaymentExecute: ['', routes.tasks, routes.userPayment, routes.paymentExecute].join('/'),
  userActivity: ['', routes.tasks, routes.myActivity].join('/'),
  userActivitySolution: ['', routes.tasks, routes.myActivity, routes.finish].join('/'),
  userActivityDispute: ['', routes.tasks, routes.myActivity, routes.dispute].join('/'),
  switchUserType: ['', routes.switchUserType].join('/'),
  adminPlaces: ['', routes.adminPage, routes.places].join('/'),
  adminPlaceInfo: (placeId) => ['', routes.adminPage, routes.places, routes.placeInfo, placeId].join('/'),
  adminPlaceInfoWithoutId: ['', routes.adminPage, routes.places, routes.placeInfo].join('/'),
  adminPayment: (placeId) => ['', routes.adminPage, routes.places, routes.placeInfo, placeId, routes.payment].join('/'),
  adminRules: ['', routes.adminPage, routes.places, routes.rules].join('/'),
  adminForCompanies: ['', routes.adminPage, routes.places, routes.forCompanies].join('/'),
  signRules: ['', routes.signUp, routes.rules].join('/'),
  adminPaymentCode: (placeId) => [
    '', routes.adminPage, routes.places, routes.placeInfo, placeId, routes.payment, routes.paymentCode,
  ].join('/'),
};
