import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

function CustomBackdrop(props) {
  return (
    <Backdrop open={props.open} style={{ zIndex: 5000 }} timeout={100}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default CustomBackdrop;
