import successImg from './successImage.svg';
import React from 'react';
import './success-popup-content.scss';
import { changeSuccessPopupState } from '../../../store/uiSlice';
import { setNeedUpdate } from '../../../store/tasksSlice';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { completedRoutes } from '../../../common/constants';
import { useTranslation } from 'react-i18next';

function SuccessPopupContent(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { type, isOpen, data = {} } = props;

  if (!isOpen) return null;

  const mapping = {
    default: {
      description: t('successMessages.default.description'),
      handleClose() {
        props.changeSuccessPopupState({ type: 'default', isOpen: false });
      },
      title: t('successMessages.default.title'),
    },
    taskComplete: {
      description: t('successMessages.taskComplete.description'),
      handleClose() {
        props.changeSuccessPopupState({ type: 'default', isOpen: false });
        history.push(completedRoutes.userTasks);
        props.setNeedUpdate({ needUpdate: true });
      },
      title: t('successMessages.taskComplete.title'),
    },
    sendAppeal: {
      description: t('successMessages.sendAppeal.description'),
      handleClose() {
        props.changeSuccessPopupState({ type: 'default', isOpen: false });
        history.push(completedRoutes.userTasks);
      },
      title: t('successMessages.sendAppeal.title'),
    },
    adminPayment: {
      description: t('successMessages.payment.description'),
      handleClose() {
        props.changeSuccessPopupState({ type: 'default', isOpen: false });
        history.push(completedRoutes.adminPlaceInfo(data.placeId), { placeId: data.placeId });
      },
      title: t('successMessages.payment.title', { count: data.amount }),
    },
    userPayment: {
      description: t('successMessages.payment.description'),
      handleClose() {
        props.changeSuccessPopupState({ type: 'default', isOpen: false });
        history.push(completedRoutes.userTasks);
      },
      title: t('successMessages.payment.title', { count: data.amount }),
    },
  };

  const modalData = mapping[type];

  return (
    <div className="success-popup-container">
      <div className="cnt">
        <img src={successImg} alt="success" className="img" />
        <h2 className="title">{modalData.title}</h2>
        <span className="description">
          {modalData.description}
        </span>
        <div className="cnt-button">
          <button className="btn-ok" onClick={modalData.handleClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  type: state.ui.successPopupState.type,
  isOpen: state.ui.successPopupState.isOpen,
  data: state.ui.successPopupState.data,
});

const actionCreators = {
  changeSuccessPopupState: changeSuccessPopupState,
  setNeedUpdate: setNeedUpdate,
};

export default connect(mapStateToProps, actionCreators)(SuccessPopupContent);
