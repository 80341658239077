import { createSlice } from '@reduxjs/toolkit';
import * as service from '../service/likengo';
import tz from 'tz-lookup';
import { fetchedHandler, fetchingErrorHandler, fetchingHandler, } from './common/helpers';
import { keyBy } from 'lodash';

const initialState = {
  fetchingState: 'none',
  data: [],
  error: null,
};

const regions = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    fetching: fetchingHandler,
    fetched: {
      reducer: fetchedHandler,
      prepare(payload) {
        const { data } = payload;
        const dataWithTimeZone = data.map((i) => {
          const { coord: coordinates } = i;
          const timezone = tz(...coordinates);
          return { ...i, timezone };
        });
        return {
          payload: {
            data: keyBy(dataWithTimeZone, 'sysname'),
          }
        }
      },
    },
    fetchingError: fetchingErrorHandler,
  },
});

const { reducer, actions } = regions;

export const {
  fetched, fetching, fetchingError,
} = actions;

export const fetchRegions = () => (dispatch) => {
  dispatch(fetching());
  return service.fetchRegions()
    .then((res) => {
      dispatch(fetched({ data: res }));
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export default reducer;
