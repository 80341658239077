import { sendRequest } from '../common/sendRequest';
import { getUrl } from './settings';

export const register = (phone, email) => {
  const url = getUrl('register');
  const body = {};
  if (phone) body.phone = phone.toLowerCase();
  if (email) body.email = email.toLowerCase();
  return sendRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      ...body,
    })
  });
};

export const auth = (phone, email, password) => {
  const url = getUrl('auth');
  const body = {
    password,
  };
  if (phone) body.phone = phone.toLowerCase();
  if (email) body.email = email.toLowerCase();
  return sendRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      ...body,
    })
  });
};

export function refreshAccessToken(token) {
  const url = getUrl('auth');
  return sendRequest(url, {
    method: 'PUT',
    body: JSON.stringify({
      token,
    })
  });
}
