import { createSlice } from '@reduxjs/toolkit';
import * as service from '../service/likengo';
import { fetchedHandler, fetchingErrorHandler, fetchingHandler, } from './common/helpers';
import { AuthTokenUtils } from '../common/authTokenStorage';
import { authLogout } from './auth/authSlice';
import { findIndex, keyBy } from 'lodash';

const initialState = {
  fetchingState: 'none',
  userPlacesFetchingState: 'none',
  data: [],
  userPlaces: {
    byId: {},
    allIds: [],
  },
  error: null,
};

const places = createSlice({
  name: 'places',
  initialState,
  reducers: {
    fetching: fetchingHandler,
    fetched: fetchedHandler,
    fetchingError: fetchingErrorHandler,
    fetchingUserPlaces(state) {
      state.userPlacesFetchingState = 'requesting';
      state.error = null;
    },
    fetchUserPlacesSuccess(state, action) {
      const { data } = action.payload;
      const byId = keyBy(data, 'likengo_id');
      const allIds = data.map((i) => i.likengo_id);
      state.userPlaces = { byId, allIds };
      state.userPlacesFetchingState = 'success';
    },
    fetchUserPlacesFailed(state) {
      state.userPlacesFetchingState = 'failed';
    },
    fetchedPlaceById(state, action) {
      state.fetchingState = 'success';
      const { data } = action.payload;
      const { likengo_id } = data;
      const index = findIndex(state.data, { likengo_id });
      state.data[index] = data;
    },
  },
  extraReducers: {
    [authLogout]: (state) => {
      state.fetchingState = 'none';
      state.data = [];
      state.error = null;
    },
  },
});

const { reducer, actions } = places;

const {
  fetched, fetching, fetchingError, fetchedPlaceById,
  fetchUserPlacesSuccess, fetchingUserPlaces, fetchUserPlacesFailed
} = actions;

export const fetchAdminPlaces = () => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId();
  return service.fetchAdminPlaces(userId)
    .then((res) => {
      dispatch(fetched({ data: res.places }));
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export const fetchUserPlaces = () => (dispatch) => {
  dispatch(fetchingUserPlaces());
  return service.fetchUserPlacesByRegion()
    .then((res) => {
      dispatch(fetchUserPlacesSuccess({ data: res.places }));
    })
    .catch((error) => {
      dispatch(fetchUserPlacesFailed({ error }));
      throw error;
    });
};

export const getPlaceById = (placeId) => (dispatch) => {
  dispatch(fetching());
  return service.getPlaceById(placeId)
    .then((res) => {
      dispatch(fetchedPlaceById({ data: res }));
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export default reducer;
