import React, { useEffect } from 'react';
import './switch-user-type.scss';
import logo from '../../../logo126.svg';
import OutlinedButton from '../../common/OutlinedButton';
import { Redirect, useHistory } from 'react-router';
import { completedRoutes } from '../../../common/constants';
import * as placesActions from '../../../store/placesSlice';
import { changeErrorPopupState } from '../../../store/uiSlice';
import { connect } from 'react-redux';
import CustomBackdrop from '../../common/CustomBackdrop';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';

function SwitchUserType(props) {
  const history = useHistory();
  const { t } = useTranslation();

  const { hasPlaces, fetchAdminPlaces, changeErrorPopupState, fetchingState } = props;

  useEffect(() => {
    const fetchData = () => {
      fetchAdminPlaces()
        .catch((error) => {
          if (error.status === 401) {
            history.replace(completedRoutes.signUp, { currentUrl: completedRoutes.switchUserType });
          } else {
            console.error(error);
            changeErrorPopupState({ type: 'default', error, isOpen: true });
          }
        })
    };
    fetchData();
  }, [fetchAdminPlaces, changeErrorPopupState, history]);

  if (fetchingState === 'none' || fetchingState === 'requesting') {
    return <CustomBackdrop open />
  }

  if (!hasPlaces) {
    return <Redirect to={completedRoutes.userTasks} />;
  }

  const handleUserClick = () => {
    history.replace(completedRoutes.userTasks);
  };
  const handleAdminClick = () => {
    history.replace(completedRoutes.adminPlaces);
  };

  return (
    <Container>
      <div className="switch-user-type">
        <img src={logo} alt="Like&amp;Go" className="logo" height="126" width="124" />
        <div className="content">
          <p>{t('switchUserType.p1')}</p>
          <div className="buttons">
            <OutlinedButton text={t('common.placeAdmin')}
                            className="out-button"
                            onClick={handleAdminClick}
            />
            <OutlinedButton text={t('common.user')}
                            className="out-button"
                            onClick={handleUserClick}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  hasPlaces: state.places.data.length !== 0 && state.places.fetchingState === 'success',
  fetchingState: state.places.fetchingState,
});

const actionCreators = {
  fetchAdminPlaces: placesActions.fetchAdminPlaces,
  changeErrorPopupState: changeErrorPopupState,
};

export default connect(mapStateToProps, actionCreators)(SwitchUserType);
