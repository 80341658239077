export const fetchingHandler = (state) => {
  state.fetchingState = 'requesting';
  state.error = null;
};

export const fetchedHandler = (state, action) => {
  const { payload } = action;
  state.fetchingState = 'success';
  state.data = payload.data;
  state.error = null;
};

export const fetchingErrorHandler = (state, action) => {
  const { payload } = action;
  state.fetchingState = 'failed';
  state.error = payload.error;
};
