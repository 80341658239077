import { AuthTokenStorage, JWToken } from '../../common/authTokenStorage';
import * as authService from '../../service/auth';
import * as auth from './authSlice';

function refreshAccessToken(dispatch) {
  let tokenRefreshingPromise = authService.refreshAccessToken(AuthTokenStorage.refreshToken).then(tokens => {
    AuthTokenStorage.setTokens(tokens.accessToken, tokens.refreshToken);
    dispatch(auth.authTokenRefreshingDone());
  }).catch(error => {
    dispatch(auth.authTokenRefreshingDone());
    auth.logout(dispatch);
    dispatch(auth.authError({ error }));
  });
  dispatch(auth.authRefreshToken({ tokenRefreshingPromise }));
  return tokenRefreshingPromise;
}

export const refreshToken = store => next => action => {
  if (typeof action !== 'function') {
    return next(action);
  }
  if (!AuthTokenStorage.isAccessTokenDefined()) {
    return next(action);
  }
  let ajwt = new JWToken(AuthTokenStorage.accessToken);
  if (!ajwt.parse()) {
    return auth.logout(store.dispatch);
  }
  if (ajwt.isExpired()) {
    if (AuthTokenStorage.isRefreshTokenDefined()) {
      let rjwt = new JWToken(AuthTokenStorage.refreshToken);
      if (rjwt.parse() && !rjwt.isExpired()) {
        if (store.getState().auth.tokenRefreshingPromise === null) {
          refreshAccessToken(store.dispatch);
        }
        return store.getState().auth.tokenRefreshingPromise.then(() => {
          return next(action)
        });
      } else {
        return auth.logout(store.dispatch);
      }
    } else {
      return auth.logout(store.dispatch);
    }
  } else {
    return next(action);
  }
};
