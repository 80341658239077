import React from 'react';
import slide1 from './explainPopup1.svg'
import CustomCarousel from '../../../common/CustomCarousel/CustomCarousel';
import { useHistory } from 'react-router';

const slides = [
  {
    id: 0,
    img: slide1,
    h: 290,
  },
];

export default function ExplainPopup() {
  const history = useHistory();

  const handleOk = () => {
    history.goBack();
  };

  return <CustomCarousel slides={slides}
                         translationNamespace={'userPaymentExplain.popup'}
                         handleOk={handleOk}
                         noMedia
                         popup
  />
}
