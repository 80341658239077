import { createSlice } from '@reduxjs/toolkit';
import * as authService from '../../service/auth';
import { AuthTokenStorage, AuthTokenUtils, JWToken } from '../../common/authTokenStorage';
import { ComplexError } from '../../common/complexError';
import dispatch from '../../index';

const initialState = {
  registerError: null,
  verificationError: null,
  tokenRefreshingPromise: null,
  hasVerificationCode: false,
  passwordValidityTerm: 0,
  isLogged: !AuthTokenUtils.isExpiredRefreshToken() && AuthTokenStorage.isAccessTokenDefined(),
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    registerHandler: (state, action) => {
      const { passwordValidityTerm } = action.payload;
      state.registerError = null;
      state.hasVerificationCode = true;
      state.passwordValidityTerm = passwordValidityTerm;
    },
    authLoggedIn: (state) => {
      state.registerError = null;
      state.verificationError = null;
    },
    authLoginSuccess: (state) => {
      state.registerError = null;
      state.verificationError = null;
      state.hasVerificationCode = false;
      state.isLogged = true;
    },
    authError: (state, action) => {
      const { registerError = null, verificationError = null } = action.payload;
      state.registerError = registerError;
      state.verificationError = verificationError;
    },
    authLogout: () => {
      AuthTokenStorage.removeTokens();
      return {
        ...initialState,
        isLogged: false,
      };
    },
    authRefreshToken: (state, action) => {
      const { tokenRefreshingPromise } = action.payload;
      state.tokenRefreshingPromise = tokenRefreshingPromise;
    },
    authTokenRefreshingDone: (state) => {
      state.tokenRefreshingPromise = null;
      state.isLogged = true;
    },
    resetRegistration: () => initialState,
  },
});

const { reducer, actions } = auth;

export const {
  authLoggedIn, authLoginSuccess, authError, authLogout,
  authRefreshToken, authTokenRefreshingDone, registerHandler, resetRegistration,
} = actions;

export const register = (phone, email) => (dispatch) => {
  dispatch(authLoggedIn());
  return authService.register(phone, email)
    .then((res) => {
      const { passwordValidityTerm } = res;
      dispatch(registerHandler({ phone, email, passwordValidityTerm }));
    })
    .catch((error) => {
      dispatch(authError({ registerError: error }));
      throw error;
    });
};

export const sendCode = (phone, email, password) => (dispatch) => {
  dispatch(authLoggedIn());
  return authService.auth(phone, email, password)
    .then(tokens => {
      const rjwt = new JWToken(tokens.accessToken);
      if (rjwt.parse()) {
        AuthTokenStorage.setTokens(tokens.accessToken, tokens.refreshToken);
        return dispatch(authLoginSuccess({
          phone: rjwt.phone,
          email: rjwt.email,
          id: rjwt.id,
        }));
      }
      const error = new ComplexError();
      error.clientMessage = "Incorrect access token format";
      dispatch(authError({ verificationError: error }));
      throw error;
    })
    .catch(error => {
      error.clientMessage = "Could not log in";
      dispatch(authError({ verificationError: error }));
      throw error;
    });
};

export const logout = () => dispatch(authLogout());

export default reducer;
