export class ComplexError {
  constructor(status = null, textStatus = null, serverMessage = null, clientMessage = null) {
    this._status = status;
    this._textStatus = textStatus;
    this._serverMessage = serverMessage;
    this._clientMessage = clientMessage;
  }

  set status(status) {
    this._status = status;
  }

  set textStatus(textStatus) {
    this._textStatus = textStatus;
  }

  set serverMessage(serverMessage) {
    this._serverMessage = serverMessage;
  }

  set clientMessage(clientMessage) {
    this._clientMessage = clientMessage;
  }

  get status() {
    return this._status;
  }

  get textStatus() {
    return this._textStatus;
  }

  get serverMessage() {
    return this._serverMessage;
  }

  get clientMessage() {
    return this._clientMessage;
  }

  toString() {
    let resStatus = "";
    if (this._status !== null) {
      resStatus += this._status;
    }
    if (this._textStatus !== null) {
      resStatus += (resStatus.length > 0) ? " - " : "";
      resStatus += this._textStatus;
    }
    let msg = "";
    if (this._clientMessage !== null) {
      msg += this._clientMessage;
    }
    if (this._serverMessage !== null) {
      msg +=  (msg.length > 0) ? ": " : ""; 
      msg += this._serverMessage;
    }
    let res = resStatus;
    res += (resStatus.length > 0) ? ". " : "";
    res += msg;
    return res;
  }

  static isComplexError(error) {
    return (error instanceof ComplexError);
  }
}
