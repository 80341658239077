import { Base64 } from 'js-base64';

const AccessTokenFieldName = 'access_token';
const RefreshTokenFieldName = 'refresh_token';

const getField = (field) => localStorage.getItem(field);
const removeField = (field) => {
  localStorage.removeItem(field);
};
const setField = (field, value) => {
  localStorage.setItem(field, value);
};

export class AuthTokenStorage {
  static setTokens(accessToken, refreshToken) {
    setField(AccessTokenFieldName, accessToken);
    setField(RefreshTokenFieldName, refreshToken);
  }

  static isAccessTokenDefined() {
    return getField(AccessTokenFieldName) !== null;
  }

  static isRefreshTokenDefined() {
    return getField(RefreshTokenFieldName) !== null;
  }

  static removeTokens() {
    removeField(AccessTokenFieldName);
    removeField(RefreshTokenFieldName);
  }

  static get accessToken() {
    return getField(AccessTokenFieldName);
  }

  static get refreshToken() {
    return getField(RefreshTokenFieldName);
  }
}

export class AuthTokenUtils {
  static isExpiredRefreshToken() {
    if (!AuthTokenStorage.isAccessTokenDefined()) return false;
    const currentToken = AuthTokenStorage.refreshToken;
    const token = new JWToken(currentToken);
    if (!token.parse()) return false;
    return token.isExpired();
  }

  static getUsername() {
    if (!AuthTokenStorage.isAccessTokenDefined()) return false;
    const currentToken = AuthTokenStorage.accessToken;
    const token = new JWToken(currentToken);
    if (!token.parse()) return false;
    if (token.phone) return token.phone;
    if (token.email) return token.email;
    return false;
  }

  static getUserId() {
    if (!AuthTokenStorage.isAccessTokenDefined()) return false;
    const currentToken = AuthTokenStorage.accessToken;
    const token = new JWToken(currentToken);
    if (!token.parse()) return false;
    return token.id;
  }
}

export class JWToken {
  constructor(token) {
    this.token = token;
    this.exp = null;
    this.id = null;
    this.phone = null;
    this.email = null;
  }

  parse() {
    let base64Url = this.token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    let json = JSON.parse(Base64.decode(base64));
    if (json.exp === undefined)
      return false;
    this.exp = json.exp;
    if (json.id === undefined)
      return false;
    this.id = json.id;
    if (json.phone === undefined)
      return false;
    this.phone = json.phone;
    if (json.email === undefined)
      return false;
    this.email = json.email;
    return true;
  }

  isExpired() {
    return this.exp < Date.now() / 1000;
  }
}
