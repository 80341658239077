import React from 'react';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <div>
          <h1>{t('errorMessages.errorBoundary.header')}</h1>
          <p>{t('errorMessages.errorBoundary.p')}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
