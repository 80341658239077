import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Carousel from 're-carousel';
import React from 'react';
import './custom-carousel.scss';

const IndicatorDots = (props) => {
  const { index, total } = props;
  if (total <= 1) return null;
  const slides = [...new Array(total).keys()];
  return (
    <div className="dots">
      {slides.map((id) => {
        return (
          <div className={`dot ${index === id ? 'active' : ''}`} key={`dot-${id}`} />
        )
      })}
    </div>
  );
};

function CustomCarousel(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { slides, setFirstVisit, translationNamespace, handleOk } = props;

  const handleClick = () => {
    setFirstVisit && setFirstVisit(false);
    handleOk ? handleOk() : history.replace('/');
  };

  const frames = slides.map((i) => {
    const { img, id } = i;
    return (
      <div className="slider-item" key={`slide-${id}`}>
        <div className="img-cnt">
          <img src={img} alt="slide" className={
            `img ${props.noMedia ? 'no-media' : ''} ${props.popup ? 'popup' : ''}`
          } />
        </div>
        <div className="text">
          <span>{t(`${translationNamespace}.slides.${id}`)}</span>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="slider">
        <Carousel widgets={[IndicatorDots]} frames={frames} />
        <div className="btn-cnt">
          <button className="btn" onClick={handleClick}>
            {t(`${translationNamespace}.okButton`)}
          </button>
        </div>
      </div>
    </>
  );
}

export default CustomCarousel;
