import { sendRequestWithAuth } from '../common/sendRequest';
import { getUrl } from './settings';
import { region } from '../common/constants';

export const fetchTasks = (userId, region) => {
  const url = getUrl(`region/${region}/tasks${userId ? `?user_id=${userId}` : ''}`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const getTaskSolutionsCountById = (userId, taskId) => {
  const url = getUrl(`tasks/${taskId}/rest_solution_count?${userId ? `user_id=${userId}` : ''}`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const fetchRegions = () => {
  const url = getUrl('regions');
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const getActivities = (userId) => {
  const url = getUrl(`users/${userId}/activities`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const postSolution = (userId, taskId) => {
  const url = getUrl('solutions');
  return sendRequestWithAuth(url, {
    method: 'POST',
    body: JSON.stringify({
      user_id: userId,
      task_id: taskId,
    })
  })
};

export const finishingSolution = (userId, solutionId, values) => {
  const { value } = values;
  const url = getUrl(['solutions', solutionId, 'finishing'].join('/'));
  return sendRequestWithAuth(url, {
    method: 'POST',
    body: JSON.stringify({
      solution_text: value,
      user_id: userId,
    })
  })
};

export const finishingSolutionImages = (userId, solutionId, formData) => {
  formData.append('user_id', userId);
  const url = getUrl(['solutions', solutionId, 'images_uploading'].join('/'));
  return sendRequestWithAuth(url, {
    method: 'POST',
    body: formData,
  })
};

export const getSolutions = (userId) => {
  const url = getUrl(`solutions?user_id=${userId}`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const postAppeal = (userId, solutionId, values) => {
  const { appealText } = values;
  const url = getUrl(`solutions/${solutionId}/appeal`);
  return sendRequestWithAuth(url, {
    method: 'POST',
    body: JSON.stringify({
      appeal_text: appealText,
      user_id: userId,
    })
  })
};

export const fetchAdminPlaces = (userId) => {
  const url = getUrl(`users/${userId}/places`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const fetchUserPlacesByRegion = () => {
  const url = getUrl(`places?region=${region}`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const postPaymentRequest = (placeId, amount, userId) => {
  const url = getUrl(`places/${placeId}/payments`);
  return sendRequestWithAuth(url, {
    method: 'POST',
    body: JSON.stringify({
      amount,
      operator_id: userId,
    })
  });
};

export const getPaymentById = (paymentId) => {
  const url = getUrl(`payments/${paymentId}`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const getPaymentByCode = (paymentCode) => {
  const url = getUrl(`payments/code/${paymentCode}`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const paymentExecute = (userId, paymentId) => {
  const url = getUrl(`payments/${paymentId}/execute`);
  return sendRequestWithAuth(url, {
    method: 'POST',
    body: JSON.stringify({
      user_id: userId,
    })
  });
};

export const paymentCancel = (placeId, paymentId) => {
  const url = getUrl(`places/${placeId}/payments/${paymentId}/cancel`);
  return sendRequestWithAuth(url, {
    method: 'POST',
  });
};

export const getUserBalance = (userId) => {
  const url = getUrl(`users/${userId}/balance`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};

export const getPlaceById = (placeId) => {
  const url = getUrl(`places/${placeId}`);
  return sendRequestWithAuth(url, {
    method: 'GET',
  });
};
