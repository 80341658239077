import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSideBarOpen: false,
  errorPopupState: {
    type: 'default',
    isOpen: false,
    error: null,
    data: {},
  },
  successPopupState: {
    type: 'default',
    isOpen: false,
    data: {},
  },
};

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setOpenState(state, action) {
      const { payload } = action;
      state.isSideBarOpen = payload.open;
    },
    changeErrorPopupState(state, action) {
      const { payload } = action;
      state.errorPopupState.type = payload.type;
      state.errorPopupState.isOpen = payload.isOpen;
      state.errorPopupState.error = payload.error || null;
      state.errorPopupState.data = payload.data;
    },
    changeSuccessPopupState(state, action) {
      const { payload } = action;
      state.successPopupState.type = payload.type;
      state.successPopupState.isOpen = payload.isOpen;
      state.successPopupState.data = payload.data;
    },
  },
});

const { reducer, actions } = ui;

export const {
  setOpenState, changeErrorPopupState, changeSuccessPopupState,
} = actions;

export default reducer;
