import { createSlice } from '@reduxjs/toolkit';
import * as service from '../service/likengo';
import { fetchingErrorHandler, fetchingHandler, } from './common/helpers';
import { AuthTokenUtils } from '../common/authTokenStorage';

const initialState = {
  fetchingState: 'none',
  payment: {},
  cancel: {},
  error: null,
};

const payments = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    fetching: fetchingHandler,
    fetched(state, action) {
      const { data } = action.payload;
      state.fetchingState = 'success';
      state.payment = data;
    },
    fetchingError: fetchingErrorHandler,
  },
});

const { reducer, actions } = payments;

const {
  fetched, fetching, fetchingError,
} = actions;

export const getPaymentById = (paymentId) => (dispatch) => {
  return service.getPaymentById(paymentId)
    .then((res) => {
      const { payment } = res;
      dispatch(fetched({ data: payment }));
      return payment;
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export const postPaymentRequest = (placeId, amount) => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId();
  return service.postPaymentRequest(placeId, amount, userId)
    .then((res) => {
      dispatch(fetched({ data: res.payment }));
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export const paymentCancel = (placeId, paymentId) => (dispatch) => {
  dispatch(fetching());
  return service.paymentCancel(placeId, paymentId)
    .then((res) => {
      dispatch(fetched({ data: {} }));
      return res.payment;
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export const getPaymentByCode = (paymentCode) => (dispatch) => {
  dispatch(fetching());
  return service.getPaymentByCode(paymentCode)
    .then((res) => {
      dispatch(fetched({ data: res.payment }));
      return res.payment;
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export const paymentExecute = (paymentId) => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId();
  return service.paymentExecute(userId, paymentId)
    .then((res) => {
      dispatch(fetched({ data: res.payment }));
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export default reducer;
