import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { refreshToken } from './store/auth/middlewares';
import { BrowserRouter } from "react-router-dom";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import * as reducer from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.scss';
import './i18n';

serviceWorker.register();

const isProductionMode = process.env.NODE_ENV === 'production';

const middleware = [refreshToken, ...getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: true,
  thunk: true,
})];

export const store = configureStore({
  reducer,
  middleware,
  devTools: !isProductionMode,
});

let isFirstVisit = localStorage.getItem('firstVisit');
if (isFirstVisit === null) {
  isFirstVisit = true;
  localStorage.setItem('firstVisit', 'false');
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App isFirstVisit={isFirstVisit !== 'false'} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

export default store.dispatch;
