import warning from './errorWarning.svg';
import error2 from './error2.svg';
import React from 'react';
import './error-popup-content.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { changeErrorPopupState } from '../../../store/uiSlice';
import { connect } from 'react-redux';
import { setNeedUpdate } from '../../../store/tasksSlice';
import { completedRoutes } from '../../../common/constants';


function ErrorPopupContent(props) {
  const { t } = useTranslation();
  const history = useHistory();

  if (!props.isOpen) return null;

  const errorTypeMapping = {
    default: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
      },
      getMessage() {
        const { error } = props;
        if (error.status === 500) return t('errorMessages.fetch.500');
        return t('errorMessages.unknownError');
      }
    },
    postSolution: {
      handlePopupClose() {
        history.replace(completedRoutes.userTasks);
        props.changeErrorPopupState({ type: 'default', isOpen: false });
        props.setNeedUpdate({ needUpdate: true });
      },
      getMessage() {
        const { error } = props;
        if (error.status === 400) return t('errorMessages.postSolutionError');
        else return t('errorMessages.unknownError');
      },
    },
    taskComplete: {
      handlePopupClose() {
        history.replace(completedRoutes.taskInfo(props.data.taskId), { taskId: props.data.taskId });
        props.changeErrorPopupState({ type: 'default', isOpen: false });
      },
      getMessage() {
        return t('errorMessages.finishError');
      },
    },
    cancel: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
        history.replace(completedRoutes.adminPlaceInfo(props.data.placeId), { placeId: props.data.placeId });
      },
      getMessage() {
        return t('errorMessages.cancelError');
      },
    },
    cancelRequest: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
        history.goBack();
      },
      getMessage() {
        const { error } = props;
        if (error.status === 500) return t('errorMessages.fetch.500');
        return t('errorMessages.unknownError');
      },
    },
    adminPayment: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
        history.replace(completedRoutes.adminPlaceInfo, { placeId: props.data.placeId });
      },
      getMessage() {
        return (
          <div className="admin-payment-error">
            <p>{t('errorMessages.adminPayment.p1')}</p>
            <p>{t('errorMessages.adminPayment.p2')}</p>
            <p>{t('errorMessages.adminPayment.p3')}</p>
            <p>{t('errorMessages.adminPayment.p4')}</p>
          </div>
        )
      },
      title: t('errorMessages.adminPayment.title', { count: props.data.amount }),
      image: error2,
    },
    paymentCode: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
      },
      getMessage() {
        const { error } = props;
        if (error.status === 404 || error.status === 400) {
          return t('errorMessages.userPayment.invalidCode');
        } else return t('errorMessages.unknownError');
      },
    },
    paymentCodeBalance: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
        history.replace(completedRoutes.userTasks);
      },
      getMessage() {
        return (
          <div>
            {t('errorMessages.userPayment.noBalance').split('\n').map((i, idx) => {
              return <div key={idx}>{i}</div>
            })}
          </div>
        )
      },
    },
    paymentExpired: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
        history.replace(completedRoutes.userPayment);
      },
      getMessage() {
        return (
          <div>
            {t('errorMessages.userPayment.notActive').split('\n').map((i, idx) => {
              return <div key={idx}>{i}</div>
            })}
          </div>
        )
      },
    },
    solutionWasFinish: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
        history.replace(completedRoutes.userTasks);
      },
      getMessage() {
        return t('errorMessages.solutionWasFinish')
      },
    },
    appealExpired: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
      },
      getMessage() {
        return t('errorMessages.dispute.appealExpired')
      },
    },
    fileSize: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
      },
      getMessage() {
        const maxFileSize = props.data.maxFileSize / 1024 / 1024;
        return t('errorMessages.fileSize', { maxFileSize })
      },
    },
    filesCount: {
      handlePopupClose() {
        props.changeErrorPopupState({ type: 'default', isOpen: false });
      },
      getMessage() {
        return t('errorMessages.filesCount', { maxFilesCount: props.data.maxFilesCount })
      },
    },
  };

  const typeData = errorTypeMapping[props.type];

  return (
    <div className="error-popup-container">
      <div className="cnt">
        <img src={typeData.image || warning} alt="error" className="img" />
        <h2 className="title">{typeData.title || t('errorMessages.error')}</h2>
        <span className="description">
          {typeData.getMessage()}
        </span>
        <div className="cnt-button">
          <button className="btn-ok" onClick={props.handlePopupClose || typeData.handlePopupClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

ErrorPopupContent.defaultProps = {
  data: {},
};

const mapStateToProps = (state) => ({
  type: state.ui.errorPopupState.type,
  isOpen: state.ui.errorPopupState.isOpen,
  error: state.ui.errorPopupState.error,
  data: state.ui.errorPopupState.data,
});

const actionCreators = {
  changeErrorPopupState: changeErrorPopupState,
  setNeedUpdate: setNeedUpdate,
};

export default connect(mapStateToProps, actionCreators)(ErrorPopupContent);
