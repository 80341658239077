import { solutionStates } from '../common/constants';

export default {
  translation: {
    common: {
      logout: 'Выйти',
      login: 'Войти',
      scoreToRubblesInfo: '1 балл = 1 рубль',
      userPayment: "Оплата баллами",
      do: "Выполнить",
      send: "Отправить",
      addImage: 'Добавить изображение',
      addImageDragActive: "Перетащите сюда",
      next: "Далее",
      open: "Открыто",
      close: "Закрыто",
      typeText: "Введите текст",
      back: "Назад",
      noTasks: "Заданий нет",
      admin: "Представитель",
      placeAdmin: 'Представитель места',
      user: 'Пользователь',
      cancel: "Отмена",
      addTask: "Добавить своё задание",
    },
    adminPayment: {
      title: "Сумма к оплате",
      inputLabel: "Минимум 10 баллов",
      errors: {
        1: "Минимальная сумма 10",
      },
      p1: 'Уточните у покупателя сумму, которую он хочет оплатить с телефона. Минимум 10 баллов.',
      codeTitle: "Сообщите код оплаты",
      p2: "Сообщите (покажите) этот код покупателю и ожидайте оплату. После оплаты придет сообщение о пополнении вашего баланса",
      cancel: "Отмена",
      input: 'Минимальная сумма 10',
    },
    placesGrid: {
      changePlace: 'Изменить место',
      header: "Мои заведения",
      balance: "Баланс заведения",
      footer1: "Добавить еще заведение?",
      footer2: "Напишите на",
      tasksCount: "Количество заданий",
      getUserPayment: "Принять платёж",
    },
    switchUserType: {
      p1: "Продолжить как"
    },
    userPayment: {
      title: "Введите код",
      p1: "",
      p2: "Код сообщит представитель заведения, который принимает платеж во время покупки",
      p3_0: "Спишет с вашего баланса <1>{{count}}</1> балл \n\n\n Вы согласны? \n",
      p3_1: "Спишет с вашего баланса <1>{{count}}</1> балла \n\n\n Вы согласны? \n",
      p3_2: "Спишет с вашего баланса <1>{{count}}</1> баллов \n\n\n Вы согласны? \n",
      pay: 'Оплатить',
      wtf: 'Что это такое?',
    },
    iosPwaPrompt: {
      copyTitle: "Установите приложение",
      copyBody: "Для того чтобы установить страницу как приложение, выполните следующие действия:",
      copyShareButtonLabel: "Нажмите кнопку 'Поделиться'",
      copyAddHomeButtonLabel: "Выберите 'Добавить на главный экран'",
      copyClosePrompt: "Закрыть",
    },
    activities: {
      myActivities: "Мои активности",
      activitiesEmpty: "Активностей нет",
      userToPlace: 'Списание средств',
    },
    successMessages: {
      default: {
        title: "Успешно",
        description: "",
      },
      taskComplete: {
        title: "Успешно отправлено",
        description: "По правилам, у представителя заведения есть 3 дня на проверку. Мы уведомим вас о результатах.",
      },
      sendAppeal: {
        title: "Успешно отправлено",
        description: "Мы во все разберемся и найдем истину. О результате сообщим в течение 3-x рабочих дней",
      },
      payment: {
        title_0: "Оплата на {{count}} балл прошла успешно",
        title_1: "Оплата на {{count}} балла прошла успешно",
        title_2: "Оплата на {{count}} баллов прошла успешно",
        description: "",
      },
    },
    userPlaces: {
      h: 'Где потратить баллы',
      allSelect: 'Все заведения',
      check1: 'С заданиями',
      check2: 'Принимают баллы',
      scoreApproved: 'Принимаем баллы',
      activityTasks: 'Активных заданий: {{count}}',
    },
    errorMessages: {
      error: 'Ошибка',
      fileSize: 'Некоторые файлы имеют слишком большой размер. \n Максимальный размер {{maxFileSize}}МБ',
      filesCount: 'Вы загрузили слишком много файлов. \n Максимальное количество файлов: {{maxFilesCount}}',
      postSolutionError: "Не удалось начать выполнение задания. Попробуйте снова немного позже.",
      solutionWasFinish: "Это задание можно выполнить только один раз. Вы уже отправляли решение.",
      finishError: "Вы отправляли решение слишком долго. \n Попробуйте снова.",
      fetch: {
        500: "Неизвестная ошибка. Повторите попытку позже.",
      },
      unknownError: "Не удалось установить соединение с сервером. Проверьте подключение к Интернету.",
      cancelError: "Отмена оплаты невозможна: покупатель уже использовал код.",
      errorBoundary: {
        header: "Что-то пошло не так.",
        p: "Перезапустите приложение.",
      },
      adminPayment: {
        title_0: "Отказ. Оплата на {{count}} балл не прошла.",
        title_1: "Отказ. Оплата на {{count}} балла не прошла.",
        title_2: "Отказ. Оплата на {{count}} баллов не прошла.",
        p1: "Возможные причины:",
        p2: "время оплаты истекло, повторите приём платежа;",
        p3: "нет интерента на вашем смартфоне или у покупателя;",
        p4: "недостаточно средств на балансе у покупателя. Уточните сумму и повторите приём платежа.",
      },
      userPayment: {
        invalidCode: "Код неверный",
        notActive: "Время оплаты истекло.\n Повторите платеж.",
        noBalance: "Не удалось списать баллы со счета.\n Недостаточно средств.",
      },
      dispute: {
        appealExpired: 'Срок подачи апелляции истек',
      },
    },
    dispute: {
      dispute: "Оспорить",
      messageText: "Текст сообщения",
      yourDispute: "Вы оспорили решение.",
      appealExplain: 'У вас есть сутки, чтобы оспорить отказ.',
      timeOver: 'Время истекло',
      restTime: 'Осталось ',
    },
    solutionStatuses: {
      [solutionStates.moderated]: "На проверке",
      [solutionStates.approved]: "Принято",
      [solutionStates.rejected]: "Отклонено",
      [solutionStates.executing]: "В работе",
      [solutionStates.expired]: "Истекло",
      [solutionStates.rejectedAppeal]: 'Апелляция отклонена',
      [solutionStates.approvedAppeal]: 'Принято по апелляции',
    },
    placeInfo: {
      openDesc: "Читать далее",
      closeDesc: "Свернуть",
      activeTasks: "Активные задания",
      workTimes: "Время работы",
      noTasks: "Сейчас у нас нет активных заданий для вас."
    },
    cities: {
      voronezh: "Воронеж",
    },
    registrationForm: {
      name: "Имя",
      value: "Телефон или электронная почта",
      verificationCode: "Код подтверждения",
      verificationCodeError: "Код неверный",
      error: "Ошибка",
      submitButton: "Продолжить",
      footer: {
        forCompanies: "Для компаний",
        termsOfUse: "Авторизуясь, вы соглашаетесь с условиями <1>пользовательского соглашения</1>" +
          " и разрешаете обработку персональных данных с соблюдением требования закона РФ от 27.07.2006 № 152-ФЗ."
      },
      pageType: {
        h: 'Авторизация',
        p: 'Для продолжения использования платформы «Задания» необходимо авторизоваться',
      }
    },
    navBar: {
      home: "Домой",
      tasks: "Заработать",
      myActivity: "Моя активность",
      rules: "Правила",
      myPlaces: "Мои заведения",
      forCompanies: "Для компаний",
      userPayment: 'Оплатить баллами',
      userPlaces: 'Где потратить',
    },
    welcomeSlider: {
      okButton: "Всё понятно",
      slides: {
        0: 'Выполняй простые задания от заведений и получай вознаграждение',
        1: 'Публикуй фото, пиши честные отзывы, получай награду за то что и так делал раньше!',
        2: 'Оплачивай баллами до 100% покупки в любом заведении, участвующем в программе',
      }
    },
    userPaymentExplain: {
      okButton: "Пропустить",
      slides: {
        0: 'Вы можете оплачивать покупки баллами в любых заведениях- участниках платформы',
        1: 'Частично или полностью, до 100% покупки',
        2: 'Сообщите представителю заведения о желании оплатить баллами платформы «Задания» и желаемую сумму',
        3: 'Представитель заведения назовёт вам 6-ти значный код оплаты',
        4: 'Для оплаты вам останется ввести этот код и подтвердить платеж! Всё! Оплата совершена! :)',
      },
      popup: {
        okButton: 'Понятно',
        slides: {
          0: 'Сообщите представителю заведения, \n' +
            'что хотите оплатить баллами платформы «Задания» и назовите сколько баллов',
        }
      }
    },
    companyTask: {
      count: "Осталось заданий",
      reward: "Награда за выполнение",
      price_0: "{{count}} балл",
      price_1: "{{count}} балла",
      price_2: "{{count}} баллов",
      rulesInfo: "<1>Внимание:</1>\nПосле нажатия на кнопку «Выполнить»" +
        " у вас будет <1>15 минут</1>, чтобы отправить решение.\n" +
        "\n<2>Все правила платформы</2>"
    },
    taskFinish: {
      rulesInfo: "<1>Внимание:</1>\nУ вас есть <1>15 минут</1>, чтобы отправить решение.\n" +
        "Или подготовьтесь, и повторите выполнение позже.\n" +
        "\n<2>Все правила платформы</2>"
    },
    balance: {
      availableBalance: 'Доступный остаток',
    },

    forCompanies: {
      h1: "Мотивируйте людей развивать ваш бизнес",
      s1: {
        p1: 'Используйте платформу «Задания» \nдля решения простых маркетинговых задач: ',
        p2: [
          'публикации честных отзывов от ваших посетителей на популярных ресурсах;',
          'размещение постов и stories в соцсетях;',
          'с упоминанием вашего заведения;',
          'стимулирование посещений и покупок;',
          'продвижение отдельных товаров и услуг;',
          'сбор откровенных фидбеков о работе вашего бизнеса и сотрудников от тайных покупателей;',
          'любые другие задания в рамках законов РФ :)',
        ],
        p3: 'Это интересно. Создать задание!',
      },
      s2: {
        h: 'Как это работает',
        li1: {
          title: 'Создаёте задания',
          text: 'определяете условия, количество выполнений, сроки и размер вознаграждения' +
            ' за каждое выполнение <1>(1 балл = 1 руб)</1> ' +
            'Готовые шаблоны и наши консультации вам в помощь! ) ' +
            'Есть нестандартные запросы? Пишите <2>tasks@likengo.ru</2>',
        },
        li2: {
          title: 'Задания выполняются',
          text: 'А вы в лице каждого исполнителя получаете микроблогера, ' +
            'лояльного клиента или тайного покупателя, адвоката бренда и агента влияния. Кто это, ' +
            'все зависит от ваших целей.',
        },
        li3: {
          title: 'Все под контролем',
          text: 'вы видите ход выполнения заданий и вознаграждаете только успешные решения. ' +
            'А нет времени следить, можете поручить это нам. Мы справимся и отчитаемся!'
        },
      },
      s3: {
        h: 'Лучше всего подходит',
        p1: 'Бизнесу в сфере услуг и розничных продаж',
        p2: 'Бизнесу покрупнее в массовых продажах',
        ul1: [
          'Кафе, бары, рестораны',
          'Салоны красоты, барбершопы, парикмахерские',
          'Фитнес-клубы, тренажерные залы',
          'Косметология',
          'Стоматология, медицинские услуги',
          'Автосервисы',
          'Отели, хостелы',
          'Цветы, подарки',
          'Образовательные курсы, детские центры развития',
          'Кинотеатры, театры, музеи',
        ],
        ul2: [
          'Ритейл (продукты, техника)',
          'Операторы связи',
        ],
      },
      s4: {
        h: 'Варианты применения',
        li1: {
          title: 'Социальный маркетинг (SMM), контент-маркетинг',
          text: 'Платформа “Задания” стимулирует ваших постоянных клиентов и новых ' +
            'посетителей писать о вас в соцсетях, упоминать вас, делиться фото. ' +
            'Чем больше вы мелькаете в информационном поле, тем больше охваты и меньше рекламные бюджеты.\n',
        },
        li2: {
          title: 'Продажи, кроссмаркетинг',
          text: 'Позволяет организовывать совместные продвижения услуг. Все участники в едином маркетинговом пространстве.\n' +
            '  Люди могут зарабатывать баллы в одних местах, а тратить в других, оплачивая до 100% покупок! ' +
            'И да, вы можете принимать баллы от людей в качестве оплаты, а мы компенсируем их рублями!\n',
        },
        li3: {
          title: 'Повышение качества обслуживания',
          text: 'Прекрасные возможности для проведения массовых опросов, выполнения заданий тайного покупателя. ' +
            'Держите руку на пульсе, следите за бизнесом, развивайте опираясь на независимые мнения людей.' +
            ' К тому же такие мероприятия прекрасно дисциплинируют сотрудников :)\n',
        },
        li4: {
          title: 'Программы лояльности',
          text: 'Мотивируйте людей на повторные покупки, приглашения друзей и прочие активности. ' +
            'Выпил 2 чашки кофе, получи 100 баллов. Привела подругу в фитнес, получи 500 баллов. \n',
        },
      },
      footer: {
        h: 'Друзья, включайтесь! Будет интересно!',
        btn: 'Заявка на подключение',
        p: 'Есть вопросы, идеи?  <1>tasks@likengo.ru</1>'
      }
    },
    rules: {
      h_desc: "Выполняй несложные задания от заведений и получай вознаграждение",
      s_hiw: {
        h: 'Как это работает',
        li1: {
          title: 'Выполняй любые задания',
          text: 'У каждого заведения свои задания. Выбирай те, которые подходят именно тебе.',
        },
        li2: {
          title: 'Дождись проверки выполнения',
          text: 'Мы следим, чтобы заведения проверяли максимально быстро.',
        },
        li3: {
          title: 'Получи баллы на свой счёт в приложении',
          text: 'Всё очень просто: 1 балл = 1 рубль. Никаких комиссий. Ты можешь тратить все.'
        },
        li4: {
          title: 'Оплати баллами до 100% покупки',
          text: 'Баллы со счета можно тратить в любом заведении, участвующем в программе.'
        },
      },
      h_rules: "Правила платформы",
      s_auth: {
        h: 'Авторизация',
        p1: 'Выполнение заданий и оплата баллами требует вашей авторизации.<1/><2>Это бесплатно и займет меньше минуты.</2>',
        steps: [
          'Нажмите кнопку <1>Войти</1> в меню приложения.',
          'При авторизации укажите ваш телефон или email.',
          'Введите код, который придет вам sms-сообщением или письмом.'
        ],
        comments: [
          'Мы не храним у себя ваши пароли из соображений безопасности.',
          'Приложение запомнит вас на неделю. Если в течение недели от вас не будет активности, потребуется снова авторизоваться.',
          'Пользователь может иметь только один аккаунт в платформе «Задания».',
          'Номер телефона или email должны быть уникальные.',
        ]
      },
      s_executing: {
        h: 'Выполнение заданий',
        p1: 'Вы можете выполнять любые задания, без ограничений.<1/>' +
          'Некоторые задания можно выполнять многократно.<1/>' +
          'У каждого задания есть описание условий и количество баллов вознаграждения.',
        steps: [
          'Выберите задание в разделе <1>Заработать</1>',
          'Внимательно почитайте условия и следуйте им. Неправильно выполненные задания не вознаграждаются.',
          'Нажмите кнопку <1>Выполнить</1>.<2/>У вас есть 15 минут, чтобы отправить решение.<2/>' +
            'Если для выполнения задания требуется подготовить информацию, сделайте это до нажатия на кнопку.',
          'Предоставьте требуемую информацию для решения задания и нажмите кнопку <1>Отправить</1>.'
        ],
        p2: 'Историю выполненных вами заданий можно посмотреть в разделе <1>Мои активности</1>.'
      },
      s_moderation: {
        h: 'Задания на проверке',
        p1: 'Все задания проходят проверку представителями заведений. Представитель может отклонить задание, ' +
          'если оно выполнено не по условиям. Отклоненные задания не вознаграждаются.',
        p2: '<1>Максимальный срок проверки — 3 рабочих дня.</1> Обычно это происходит гораздо быстрее.',
        p3: 'Мы уведомим вас о принятии или отклонении задания с помощью sms или на email. ' +
          'Статус выполненных заданий вы в любой момент можете посмотреть самостоятельно в разделе <1>Мои активности</1>.',
        p4: 'Сразу после проверки баллы за принятые задания зачисляются на ваш бонусный счет. ' +
          'Справа в верхнем углу экрана указаны суммы <1>50</1>  <2>300</2>:',
        balnce_legend: [
          'серым цветом — стоимость заданий, которые находятся на проверке;',
          'зеленым цветом — баллы, начисленные на ваш бонусный счет.'
        ]
      },
      s_appeal: {
        h: 'Как оспорить отклоненные задания',
        p1: '<1>В течение 1 суток после проверки (отклонения) вы можете оспорить решение заведения.</1> ' +
          'Каждое отклоненное задание нужно оспаривать отдельно.',
        steps: [
          'Зайдите в раздел <1>Мои активности</1> и найдите отклоненное задание (статус Отклонено красным цветом).',
          'Нажмите на отклоненное задание.',
          'Внимательно ознакомьтесь с причиной отклонения и если не согласны в решением заведения, нажмите кнопку <1>Оспорить</1>.',
          'Напишите ваши аргументы',
          'Нажмите кнопку <1>Отправить</1>.'
        ],
        p2: 'Арбитражем спорных ситуаций занимается администрация платформы «Задания». ' +
          'Администрация изучит мнение сторон и вынесет окончательное решение. ' +
          'В некоторых ситуациях для выяснения деталей администрация связывается по телефону или по email с каждой стороной.',
        p3: '<1>Максимальный срок рассмотрения спорных ситуаций — 5 рабочих дней.</1>',
        p4: 'Решение администрации окончательно и изменению не подлежит.',
        p5: 'В случае решения в вашу пользу баллы вознаграждения за выполненное задание будут начислены на ваш бонусный счет.'
      },
      s_payment: {
        h: 'Оплата баллами',
        p1: 'Баллами можно оплачивать покупки в заведениях — участниках платформы. ' +
          'Частично или полностью. Список заведений, принимающих к оплате баллы, размещен в разделе <1>Где потратить</1>.',
        p2: 'Для оплаты баллами необходимо:',
        steps: [
          'сообщить об этом представителю заведения и назвать сумму;',
          'открыть приложение и нажать кнопку <1>Оплатить баллами</1>;',
          'введите 6-ти значный код подтверждения оплаты, который сообщит вам представитель заведения;',
          'подтвердите оплату;',
          'баллы спишутся с вашего баланса. Оплата совершена.'
        ],
        p3: 'Историю оплат (списания баллов) можно посмотреть в разделе <1>Мои активности</1>.'
      },
      s_prohibited: {
        h: 'Запрещено',
        p1: 'При работе с платформой «Задания» запрещено:',
        actions: [
          'выкладывать информацию, которая является недостоверной, незаконной, вредоносной, порнографической, ' +
          'клеветнической, нарушает права интеллектуальной собственности и авторские права;',
          'использовать нецензурную лексику;',
          'использовать нескольких аккаунтов;',
          'передавать баллы третьим лицам;',
          'выводить баллы в рубли;',
          'любые действия, нарушающие законы РФ.'
        ],
        p2: 'Для получения полной информации ознакомьтесь с <1>Пользовательским соглашением</1>.'
      }
    },
  }
};

