import React from 'react';
import ModalItem from '../ModalItem/ModalItem';

import './popup-modal.scss';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function PopupModal(props) {

  return (
    <>
      <ModalItem
        fullScreen={false} withBackButton={false}
        withTransition={false}
        withToolbar={false}
        classNameForPopup="popup-dialog-cnt"
        disableBackdropClick
        popup
        {...props}
      >
        <div className="popup">
          {props.withBackButton && (<div className="close-button">
            <IconButton
              color="main" aria-label="close"
              className="close" onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
          </div>)}
          {props.children}
        </div>
      </ModalItem>
    </>
  )
}

export default PopupModal;
