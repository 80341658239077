import { ComplexError } from './complexError';
import { AuthTokenStorage } from './authTokenStorage';
import HeadersParser from './headersParser';
import * as auth from '../store/auth/authSlice'

export const ResponseTypes = {
  json: 0,
  file: 1
};

const ResponseParsers = {
  [ResponseTypes.json]: response => response.json(),
  [ResponseTypes.file]: response => new Promise((resolve) => {
    response.blob().then(blob => {
      resolve({
        name: new HeadersParser(response.headers).getFileName(),
        data: blob
      });
    });
  })
};

function parseResponse(response, type) {
  if (ResponseParsers[type] === undefined)
    throw new Error("Unknown response type");
  return ResponseParsers[type](response);
}

export function sendRequest(url, params, responseType = ResponseTypes.json) {
  return fetch(url, params).then(response => {
    if (response.ok) {
      return parseResponse(response, responseType);
    } else {
      return response.json().then(data => {
        let error = new ComplexError(response.status, response.statusText);
        if (data['detail'] !== undefined) {
          error.serverMessage = data['detail'];
        }
        throw error;
      }).catch(error => {
        if (ComplexError.isComplexError(error)) {
          throw error;
        }
        let serror = new ComplexError(response.status, response.statusText);
        if (error instanceof Error) {
          serror.serverMessage = "Response parsing error: " + error.message;
        }
        throw serror;
      })
    }
  }).catch(error => {
    if (ComplexError.isComplexError(error)) {
      throw error;
    }
    let serror = new ComplexError();
    serror.serverMessage = "Request sending error: " + error.message;
    throw serror;
  });
}

export function sendRequestWithAuth(url, params, unauthHandler, responseType = ResponseTypes.json) {
  if (AuthTokenStorage.isAccessTokenDefined()) {
    if (params.headers === undefined) {
      params.headers = {};
    }
    params.headers['Authorization'] = 'Bearer ' + AuthTokenStorage.accessToken;
  }
  return sendRequest(url, params, responseType).catch(error => {
    if ((error.status === 401) && (unauthHandler !== null)) {
      auth.logout();
    }
    throw error;
  });
}
