import { createSlice } from '@reduxjs/toolkit';
import * as service from '../service/likengo';
import { AuthTokenUtils } from '../common/authTokenStorage';
import { fetchedHandler, fetchingErrorHandler, fetchingHandler } from './common/helpers';
import { get } from 'lodash';
import moment from 'moment';

const initialState = {
  fetchingState: 'none',
  data: [],
  error: null,
};

const activities = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    fetching: fetchingHandler,
    postSuccess(state) {
      state.fetchingState = 'success';
    },
    fetched: {
      reducer: fetchedHandler,
      prepare(payload) {
        const { solutions, expenses } = payload.data;
        const activity = [...solutions, ...expenses].sort((a, b) => {
          const date1 = get(a, 'startExecutingTime.date') || get(a, 'approvingTime.date');
          const date2 = get(b, 'startExecutingTime.date') || get(b, 'approvingTime.date');
          const m1 = moment(date1);
          const m2 = moment(date2);
          return m2.isBefore(m1) ? -1 : 1;
        });
        return {
          payload: { data: activity },
        };
      },
    },
    fetchingError: fetchingErrorHandler,
  },
});

const { reducer, actions } = activities;

export const {
  postSuccess, fetchingError, fetching, fetched,
} = actions;

export const postSolution = (taskId) => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId();
  return service.postSolution(userId, taskId)
    .then((res) => {
      dispatch(postSuccess());
      return res;
    })
    .catch((error) => {
      dispatch(fetchingError({ error: null }));
      throw error;
    });
};

export const finishingSolution = (solutionId, values) => (_dispatch) => {
  const { input1 } = values;
  const userId = AuthTokenUtils.getUserId();
  return service.finishingSolution(userId, solutionId, { value: input1 });
};

export const finishingSolutionImages = (solutionId, formData) => (_dispatch) => {
  const userId = AuthTokenUtils.getUserId();
  return service.finishingSolutionImages(userId, solutionId, formData);
};

export const postAppeal = (solutionId, values) => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId();
  return service.postAppeal(userId, solutionId, values)
    .then(() => {
      dispatch(postSuccess());
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export const getActivities = () => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId();
  return service.getActivities(userId)
    .then((res) => {
      const { solutions, expenses } = res;
      dispatch(fetched({ data: { solutions, expenses } }));
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export default reducer;
