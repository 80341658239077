import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Dialog, Hidden, IconButton, Toolbar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './modal-item.scss';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import CustomBackdrop from '../CustomBackdrop';
import IsMobileContext from '../../../common/IsMobileContext';

const animationDuration = 400;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={animationDuration} />;
});

function ModalItem(props) {
  const history = useHistory();
  const isMobileDesign = useContext(IsMobileContext);

  const {
    handleClose: propsHandleClose, userPlacesFetchingState,
    tasksFetchingState, regionsFetchingState, activitiesFetchingState
  } = props;

  const handleClose = () => {
    if (propsHandleClose !== undefined) {
      propsHandleClose();
    } else {
      history.goBack();
    }
  };

  const isLoading = (
    tasksFetchingState === 'requesting' ||
    regionsFetchingState === 'requesting' ||
    activitiesFetchingState === 'requesting' ||
    userPlacesFetchingState === 'requesting'
  );

  const isFullScreen = isMobileDesign ? props.fullScreen : false;

  if (!isMobileDesign && !props.popup) return (
    <div className="modal-item-content">
      {React.Children.only(props.children)}
    </div>
  );

  return (
    <Dialog
      fullWidth
      fullScreen={isFullScreen}
      maxWidth={props.maxWidth}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
      className={props.classNameForPopup}
      disableBackdropClick={props.disableBackdropClick}
    >
      <Hidden mdUp>
        {props.withToolbar && <Toolbar />}
      </Hidden>
      <CustomBackdrop open={isLoading} />
      <div className="modal-item-content">
        <Hidden mdUp>
          {props.withBackButton && (
            <div className={`go-back-button ${props.goBackAbsolutePosition ? 'abs' : ''}`}>
              <IconButton
                color="inherit" aria-label="go back"
                className="arrow-back" onClick={handleClose}>
                <ArrowBackIcon className={`${props.whiteArrowBack ? 'white' : ''}`} />
              </IconButton>
            </div>
          )}
        </Hidden>
        {React.Children.only(props.children)}
      </div>
      <div className="bottom-margin" />
    </Dialog>
  );
}

ModalItem.defaultProps = {
  withToolbar: true,
  withBackButton: true,
  withTransition: true,
  fullScreen: true,
  open: true,
  whiteArrowBack: false,
  maxWidth: 'sm',
  goBackAbsolutePosition: false,
  classNameForPopup: '',
  disableBackdropClick: false,
};

const mapStateToProps = state => ({
  tasksFetchingState: state.tasks.fetchingState,
  regionsFetchingState: state.regions.fetchingState,
  userPlacesFetchingState: state.places.userPlacesFetchingState,
  activitiesFetchingState: state.activities.fetchingState,
});

export default connect(mapStateToProps)(ModalItem);
