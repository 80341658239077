const UTF8Prefix = "UTF-8";

const rawurldecode = str => {
  return decodeURIComponent((str + '').replace(/%(?![\da-f]{2})/gi, () => {
      return '%25';
  }));
}

export default class HeadersParser {
  constructor(headers) {
    this.headers = headers;
  }

  getFileName() {
    let contDisp = this.headers.get('Content-Disposition');
    if (contDisp && contDisp.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(contDisp);
      if (matches != null && matches[1]) {
        let encoded = matches[1].replace(/['"]/g, '');
        if (!encoded.substr(0, UTF8Prefix.length).includes(UTF8Prefix))
          return encoded;
        encoded = encoded.substr(UTF8Prefix.length);
        return rawurldecode(encoded);
      }
    }
    return null;
  }
}
