import { createSlice } from '@reduxjs/toolkit';
import * as service from '../service/likengo';
import { fetchedHandler, fetchingErrorHandler, fetchingHandler, } from './common/helpers';
import { AuthTokenUtils } from '../common/authTokenStorage';
import { keyBy } from 'lodash';
import { region } from '../common/constants';

const initialState = {
  fetchingState: 'none',
  solutionCountFetchingState: 'none',
  data: {
    byId: {},
    allIds: [],
  },
  error: null,
  needUpdate: false,
};

const tasks = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    fetching: fetchingHandler,
    fetched: {
      reducer: fetchedHandler,
      prepare(payload) {
        const { data } = payload;
        const byId = keyBy(data, 'id');
        const allIds = data.map((i) => i.id);
        const newData = { byId, allIds };
        return {
          payload: { data: newData },
        };
      }
    },
    solutionCountFetched(state, action) {
      const { data } = action.payload;
      const { taskId, count } = data;
      state.solutionCountFetchingState = 'success';
      state.data.byId[taskId].count = count;
    },
    solutionCountFetchingFailed(state) {
      state.solutionCountFetchingState = 'failed';
    },
    solutionCountFetching(state) {
      state.solutionCountFetchingState = 'requesting';
    },
    fetchingError: fetchingErrorHandler,
    setNeedUpdate(state, action) {
      const { needUpdate } = action.payload;
      state.needUpdate = needUpdate;
    }
  },
});

const { reducer, actions } = tasks;

export const {
  fetched, fetching, fetchingError, setNeedUpdate, solutionCountFetched,
  solutionCountFetchingFailed, solutionCountFetching,
} = actions;

export const fetchTasks = () => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId() || null;
  return service.fetchTasks(userId, region)
    .then((res) => {
      const { tasks } = res;
      dispatch(fetched({ data: tasks }));
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
      throw error;
    });
};

export const getTaskSolutionsCountById = (taskId) => (dispatch) => {
  dispatch(solutionCountFetching());
  const userId = AuthTokenUtils.getUserId() || null;
  return service.getTaskSolutionsCountById(userId, taskId)
    .then((res) => {
      const { count } = res;
      dispatch(solutionCountFetched({ data: { count, taskId } }));
    })
    .catch((error) => {
      dispatch(solutionCountFetchingFailed({ error }));
      throw error;
    });
};

export default reducer;
