import React, { useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import PopupModal from './components/common/PopupModal/Popup';
import ErrorPopupContent from './components/common/ErrorPopupContent/ErrorPopupContent';
import SuccessPopupContent from './components/common/SuccessPopupContent/SuccessPopupContent';
import { changeErrorPopupState, changeSuccessPopupState } from './store/uiSlice';
import { connect } from 'react-redux';
import CustomBackdrop from './components/common/CustomBackdrop';
import ErrorBoundary from './components/common/ErrorBoundary';
import { completedRoutes } from './common/constants';
import { lazyWithDelay } from './common/lazyWithDelay';
// import { useTranslation } from 'react-i18next';
import IsMobileContext from './common/IsMobileContext';
import SwitchUserType from './components/admin/SwitchUserType/SwitchUserType'
import ExplainPopup from './components/user/UserPayment/Explain/ExplainPopup';

const AuthPage = lazyWithDelay(import('./components/pages/AuthPage'));
const WelcomePage = lazyWithDelay(import('./components/pages/WelcomePage'));
const TasksPage = lazyWithDelay(import('./components/pages/TasksPage'));
const AdminPage = lazyWithDelay(import('./components/pages/AdminPage'));

const theme = createMuiTheme({
  palette: {
    common: {
      black: '#000',
    },
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#FEEB17',
    },
    action: {
      hover: '#FEEB17',
      disabledBackground: '#FEEB17',
    }
  },
  shadows: Array(25).fill('none'),
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: [
      'Geometria',
      '-apple-system',
      'BlinkMacSystemFont',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

// const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

function App(props) {
  // const { t } = useTranslation();
  const isMobileDesign = useMediaQuery('(max-width: 959px)');
  const [isFirstVisit, setFirstVisit] = useState(props.isFirstVisit);
  const location = useLocation();

  const errorPopupClose = () => props.changeErrorPopupState({ type: 'default', isOpen: false });
  const successPopupClose = () => props.changeSuccessPopupState({ type: 'default', isOpen: false });

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <IsMobileContext.Provider value={isMobileDesign}>
          <ErrorBoundary>
            {/*{isSafari && isMobileDesign && (*/}
            {/*  <AddToHomescreen copyTitle={t('iosPwaPrompt.copyTitle')}*/}
            {/*                   copyBody={t('iosPwaPrompt.copyBody')}*/}
            {/*                   copyShareButtonLabel={t('iosPwaPrompt.copyShareButtonLabel')}*/}
            {/*                   copyAddHomeButtonLabel={t('iosPwaPrompt.copyAddHomeButtonLabel')}*/}
            {/*                   copyClosePrompt={t('iosPwaPrompt.copyClosePrompt')}*/}
            {/*                   permanentlyHideOnDismiss={false}*/}
            {/*                   timesToShow={10}*/}
            {/*  />*/}
            {/*)}*/}
            <Switch>
              <Route exact path="/">
                {
                  props.isLogged ? <Redirect to={completedRoutes.switchUserType} /> :
                    <Redirect to={completedRoutes.userTasks} />
                }
              </Route>
              <Route path={completedRoutes.signUp}>
                {isFirstVisit && isMobileDesign ?
                  <Redirect to={completedRoutes.welcome} /> :
                  props.isLogged ? <Redirect to={completedRoutes.switchUserType} /> :
                    <React.Suspense fallback={<CustomBackdrop open />}>
                      <AuthPage />
                    </React.Suspense>
                }
              </Route>
              <Route path={completedRoutes.switchUserType}>
                <SwitchUserType />
              </Route>
              <Route path={completedRoutes.adminPlaces}>
                <React.Suspense fallback={<CustomBackdrop open />}>
                  <AdminPage />
                </React.Suspense>
                <PopupModal open={props.isErrorPopupOpen} handleClose={errorPopupClose}>
                  <ErrorPopupContent />
                </PopupModal>
                <PopupModal open={props.isSuccessPopupOpen} handleClose={successPopupClose}>
                  <SuccessPopupContent />
                </PopupModal>
              </Route>
              <Route path={completedRoutes.userTasks}>
                {isFirstVisit && isMobileDesign ?
                  <Redirect to={{
                    pathname: completedRoutes.welcome,
                    state: { referrer: location.pathname }
                  }} /> :
                  <>
                    <React.Suspense fallback={<CustomBackdrop open />}>
                      <TasksPage />
                    </React.Suspense>
                    < PopupModal open={props.isErrorPopupOpen} handleClose={errorPopupClose}>
                      <ErrorPopupContent />
                    </PopupModal>
                    <PopupModal open={props.isSuccessPopupOpen} handleClose={successPopupClose}>
                      <SuccessPopupContent />
                    </PopupModal>
                  </>
                }
              </Route>
              <Route path={completedRoutes.welcome}>
                <React.Suspense fallback={<CustomBackdrop open />}>
                  <WelcomePage setFirstVisit={setFirstVisit} />
                </React.Suspense>
              </Route>
              <Route path={completedRoutes.userPaymentExplainPopup}>
                <ExplainPopup />
              </Route>
              <Redirect to={completedRoutes.switchUserType} />
            </Switch>
          </ErrorBoundary>
        </IsMobileContext.Provider>
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = state => ({
  isErrorPopupOpen: state.ui.errorPopupState.isOpen,
  isSuccessPopupOpen: state.ui.successPopupState.isOpen,
  isLogged: state.auth.isLogged,
});

const actionCreators = {
  changeErrorPopupState: changeErrorPopupState,
  changeSuccessPopupState: changeSuccessPopupState,
};

export default connect(mapStateToProps, actionCreators)(App);
