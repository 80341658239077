import React from 'react';
import { Button } from '@material-ui/core';
import './common.scss';


function OutlinedButton(props) {
  const { text, className, ...rest } = props;
  return (
    <Button variant="outlined"
            className={`outlined-btn ${className}`}
            {...rest}
    >
      <span className="text">{text}</span>
    </Button>
  );
}

export default OutlinedButton;
