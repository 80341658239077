import { createSlice } from '@reduxjs/toolkit';
import { fetchingErrorHandler, fetchingHandler } from './common/helpers';
import * as service from '../service/likengo';
import { AuthTokenUtils } from '../common/authTokenStorage';
import { authLogout } from './auth/authSlice';

const initialState = {
  fetchingState: 'none',
  error: null,
  balance: 0,
  moderatedBalance: 0,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetching: fetchingHandler,
    fetchingError: fetchingErrorHandler,
    fetchBalanceSuccess(state, action) {
      const { data: { balance, moderatedBalance } } = action.payload;
      state.fetchingState = 'success';
      state.balance = balance;
      state.moderatedBalance = moderatedBalance;
    },
  },
  extraReducers: {
    [authLogout]: () => initialState,
  }
});

const { reducer, actions } = user;

export const {
  fetching, fetchingError, fetchBalanceSuccess,
} = actions;

export const getUserBalance = () => (dispatch) => {
  dispatch(fetching());
  const userId = AuthTokenUtils.getUserId();
  return service.getUserBalance(userId)
    .then((res) => {
      const { balance, moderatedBalance } = res;
      dispatch(fetchBalanceSuccess({
        data: {
          balance,
          moderatedBalance,
        }
      }));
      return balance;
    })
    .catch((error) => {
      dispatch(fetchingError({ error }));
    });
};

export default reducer;
